export type HistoryEntry<Item, Data> = { data: Data; from: Item; to: Item };

export class History<Item, Data> {
    past: HistoryEntry<Item, Data>[];
    future: HistoryEntry<Item, Data>[];

    constructor() {
        this.past = [];
        this.future = [];
    }

    change(change: HistoryEntry<Item, Data>) {
        if (!change) return;
        this.past.push(change);
        this.future = [];
    }

    undo() {
        const change = this.past.pop();
        if (!change) return;
        this.future.push(change);
        return change;
    }

    redo() {
        const change = this.future.pop();
        if (!change) return;
        this.past.push(change);
        return change;
    }
}
