import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../main/type.map.service";

type Node = { type: "text" | "link"; value: string };

@TypeManagerDecorator("s25-ng-link-parser")
@Component({
    selector: "s25-ng-link-parser",
    template: `
        @for (node of nodes; track $index) {
            @if (node.type === "text") {
                <span>{{ node.value }}</span>
            } @else if (node.type === "link") {
                <a href="{{ node.value }}" target="_blank">{{ node.value }}</a>
            }
        }
    `,
    styles: ``,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class S25LinkParserComponent implements OnChanges, OnInit {
    @Input({ required: true }) text: string;

    nodes: Node[] = [];

    ngOnInit() {
        this.parse();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.text) this.parse();
    }

    parse() {
        this.nodes = this.parseLinks(this.text);
    }

    parseLinks(str: string): Node[] {
        if (!str) return [];

        const URL = new RegExp(
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
        );
        return String(str)
            .split(URL)
            .filter((s) => s)
            .map((str) => {
                const isURL = URL.test(str);
                const type = isURL ? "link" : "text";
                return { type, value: str };
            });
    }
}
