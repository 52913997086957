export const EventSyncConst = {
    eventChange: "event-sync-event-change",
};

export class EventSync {
    public static broadcastEventChange(eventId: string, property: string, newValue: string | number) {
        let newDetail = {
            eventId: parseInt(eventId),
            property: property,
            newValue: newValue,
        };

        dispatchEvent(new CustomEvent(EventSyncConst.eventChange, { detail: newDetail }));
    }

    public static broadcastEventNameChange(eventId: string, newVal: string) {
        return EventSync.broadcastEventChange(eventId, "name", newVal);
    }

    public static broadcastEventTitleChange(eventId: string, newVal: string) {
        return EventSync.broadcastEventChange(eventId, "title", newVal);
    }

    public static broadcastEventStateChange(eventId: string, newVal: number) {
        return EventSync.broadcastEventChange(eventId, "state", newVal);
    }
}
