import { TypeManagerDecorator } from "../main/type.map.service";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../util/s25-util";
import { DatepickerApi } from "../modules/s25-datepicker/date.picker.api";
import { PopoverComponent } from "../modules/s25-popover/popover.component";

import { PopoverModule } from "../modules/s25-popover/popover.module";
import { S25DatepickerModule } from "../modules/s25-datepicker/s25.datepicker.module";
import { S25IconModule } from "../modules/s25-icon/s25.icon.module";
import { S25ButtonComponent } from "./s25.button.component";
import { AvailWeekly } from "../modules/s25-avail-weekly/s25.avail.weekly.util";
import { UserprefService } from "../services/userpref.service";
import { Proto } from "../pojo/Proto";
import DateFormat = Proto.DateFormat;

@TypeManagerDecorator("s25-ng-inline-datepicker")
@Component({
    selector: "s25-ng-inline-datepicker",
    template: `
        <s25-ng-button [type]="'flat'" (click)="step(-1)" class="arrow-left" [ariaLabel]="'Previous Date'">
            <s25-ng-icon [type]="'arrowLeft'" />
        </s25-ng-button>
        <s25-popover
            [modelBean]="{ popoverTemplate: datePickerTemplate }"
            [openTrigger]="'click'"
            [closeTrigger]="'click'"
            (shown)="applyFocus()"
            (hidden)="restoreFocus()"
        >
            <span class="title">{{ title ?? text }}</span>
        </s25-popover>
        <s25-ng-button [type]="'flat'" (click)="step(1)" class="arrow-right" [ariaLabel]="'Next Date'">
            <s25-ng-icon [type]="'arrowRight'" />
        </s25-ng-button>

        <ng-template #datePickerTemplate>
            <s25-datepicker
                [modelValue]="{ date: firstDate, showToday: true }"
                [inputLabel]="title ?? text"
                [inline]="true"
                [autoSelectToday]="false"
                (modelValueChange)="onDatepickerChange($event)"
            ></s25-datepicker>
        </ng-template>
    `,
    styles: `
        :host {
            --link-color: #2573a7;

            display: flex;
            align-items: center;
            line-height: normal;
        }

        :host ::ng-deep s25-ng-button button {
            padding: 0;
        }

        s25-ng-icon {
            font-size: 1.25em;
            color: var(--link-color);
        }

        .title {
            cursor: pointer;
        }

        .title:hover {
            color: var(--link-color);
            text-decoration: underline;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [PopoverModule, S25DatepickerModule, S25IconModule, S25ButtonComponent],
})
export class S25InlineDatepickerComponent implements OnInit, OnChanges {
    @Input({ required: true }) firstDate: Date;
    @Input() lastDate: Date;
    @Input() weeks: number = 0;
    @Input() title: string;

    @Output() firstDateChange = new EventEmitter<Date>();
    @Output() lastDateChange = new EventEmitter<Date>();

    @ViewChild(PopoverComponent) datepickerPopover: PopoverComponent;

    isInit: boolean = false;
    dateFormat: DateFormat;
    weekStart: number = AvailWeekly.dows.Sunday;
    text: string;

    constructor(
        private elementRef: ElementRef,
        private changeDetector: ChangeDetectorRef,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.weeks || changes.firstDate || changes.lastDate) this.refresh();
    }

    async ngOnInit() {
        const [dateFormat, weekStart] = await Promise.all([
            UserprefService.getS25Dateformat(),
            UserprefService.getWeekstart(),
        ]);
        this.dateFormat = dateFormat;
        this.weekStart = weekStart;
        this.isInit = true;
        this.refresh();
    }

    refresh() {
        if (!this.isInit) return;

        if (this.weeks === 0) {
            this.text = S25Util.date.fromToString(this.firstDate, null, this.dateFormat);
        } else {
            // Set first date according to week start
            this.setFirstDate(S25Util.date.firstDayOfWeek(this.firstDate, this.weekStart));
            // Set last date according to num weeks
            this.setLastDate(
                S25Util.date.lastDayOfWeek(S25Util.date.addWeeks(this.firstDate, this.weeks - 1), this.weekStart),
            );
            // Update title
            this.text = S25Util.date.fromToString(this.firstDate, this.lastDate, this.dateFormat);
        }
        this.changeDetector.detectChanges();

        DatepickerApi.refresh(this.elementRef.nativeElement);
    }

    setFirstDate(date: Date) {
        if (!S25Util.date.equalDate(this.firstDate, date)) {
            this.firstDate = date;
            this.firstDateChange.emit(this.firstDate);
        }
    }

    setLastDate(date: Date) {
        if (!S25Util.date.equalDate(this.lastDate, date)) {
            this.lastDate = date;
            this.lastDateChange.emit(this.lastDate);
        }
    }

    step(steps: number) {
        const stepSize = this.weeks ? this.weeks * 7 : 1;
        this.setFirstDate(S25Util.date.addDays(this.firstDate, stepSize * steps));
        this.refresh();
    }

    onDatepickerChange(date?: Date) {
        if (date) this.setFirstDate(date);
        this.refresh();
    }

    applyFocus() {
        // Set focus to first datepicker button when popover is shown
        setTimeout(() => {
            const datpickerButton: HTMLButtonElement = document.querySelector(".btn.btn-link.ngb-dp-arrow-btn");
            datpickerButton?.focus?.();
        }, 200);
    }

    restoreFocus() {
        this.datepickerPopover.restoreFocus();
    }
}
