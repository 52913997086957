import { SearchCriteriaService } from "../services/search/search-criteria/search.criteria.service";
import { S25Util } from "./s25-util";
import { S25ItemI } from "../pojo/S25ItemI";

export class ObjectDetailsUtil {
    /***********************
     * filterMasterDefinitions - uses searchCriteriaService to apply master definitions filtrations
     **************************/
    public static filterMasterDefinitions(searchCriteriaMethod: string, items: S25ItemI[], idProp?: string) {
        items = S25Util.array.forceArray(items);
        idProp = idProp || "itemId";

        let searchCriteriaService: any = SearchCriteriaService;
        let normalizedItems = items.map((item: S25ItemI) => {
            item.itemId = item[idProp];
            return item;
        });

        return searchCriteriaService[searchCriteriaMethod]().then((items: S25ItemI[]) => {
            let filteredItems: any[] = S25Util.array.shallowIntersection(normalizedItems, items, ["itemId"]);
            return filteredItems.map((item) => {
                item[idProp] = item.itemId;
                return item;
            });
        });
    }
}
