import { EventFormOccurrenceUtil } from "../modules/s25-event-creation-form/occurrences/event.form.occurrence.util";
import { S25Util } from "./s25-util";
import { S25Datefilter } from "../modules/s25-dateformat/s25.datefilter.service";
import { FormModel } from "../pojo/FormI";

export class OccurrenceValidationUtil {
    public static validateMinMaxDates(formModel: any, evStartDt: any, evEndDt: any) {
        return OccurrenceValidationUtil.validateMinMaxOccurrences(formModel, [
            { evStartDt: evStartDt, evEndDt: evEndDt, minutes: formModel.timeModel.minutes },
        ]);
    }

    /*
    Checks whether the occurrences are inside the allowed date range as determined by the event form configuration
     */
    public static validateMinMaxOccurrences(formModel: FormModel, occurrences: any[]) {
        occurrences.sort(S25Util.shallowSortDates("evStartDt", "evEndDt"));

        if (occurrences && occurrences.length) {
            const firstOcc = occurrences[0],
                lastOcc = occurrences[occurrences.length - 1];

            const firstStart = firstOcc.evStartDt,
                lastEnd = lastOcc.evEndDt,
                totalFirstStart = EventFormOccurrenceUtil.getStartDt(firstOcc),
                totalLastEnd = EventFormOccurrenceUtil.getEndDt(lastOcc);

            return (
                totalFirstStart >= formModel.minCreateDate &&
                firstStart <= formModel.maxCreateDate &&
                lastEnd >= formModel.minCreateDate &&
                totalLastEnd <= formModel.maxCreateDate
            );
        } else {
            return true;
        }
    }

    public static validationErrorText(formModel: any, type: string) {
        const dateRange = `${S25Datefilter.transform(
            formModel.minCreateDate,
            formModel.dateFormat,
        )} - ${S25Datefilter.transform(formModel.maxCreateDate, formModel.dateFormat)}`;

        if (type === "extraTime") {
            return `The chosen extra time places occurrence dates outside allowable bounds (${dateRange})`;
        } else if (type === "date") {
            return `The chosen value causes dates to fall outside allowable bounds (${dateRange})`;
        }
    }
}
