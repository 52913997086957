// @author travis
import { S25Util } from "./s25-util";
import { CacheRepository } from "../decorators/cache.decorator";
import { jSith } from "./jquery-replacement";

export class ExtendUtil {
    public static extend(itemId: number, itemTypeId: number, extObj: any) {
        var items = S25Util.propertyGetParentsWithChildValue(CacheRepository._repository, "itemId", itemId);
        jSith.forEach(items, function (resultItem: any) {
            if (resultItem.itemTypeId == itemTypeId) {
                S25Util.extendDeep(resultItem, extObj);
            }
        });
    }
}
