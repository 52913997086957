//@author: kate & devin

import { jSith } from "./jquery-replacement";
import { S25Util } from "./s25-util";

export class EmbeddingUtil {
    private static singleton: EmbeddingUtil = null;

    constructor() {
        if (S25Util.isInIframe) {
            let modal = [];
            let scrolls: any[] = [];

            setInterval(function () {
                if (scrolls.length) {
                    modal = jSith.element(".modal", true);
                    if (modal && !modal.IFrameScrollY) {
                        jSith.setCss(modal, {
                            top: scrolls[scrolls.length - 1],
                        });
                    }
                }
            }, 1000);

            window.addEventListener("message", function (event) {
                try {
                    let obj = JSON.parse(event.data);
                    if (obj.type === "scroll") {
                        let scrollTop = parseInt(obj.data);
                        scrolls.push(scrollTop);
                        modal = jSith.element(".modal", true);
                        if (modal) {
                            jSith.setCss(modal, {
                                top: scrollTop,
                            });
                            modal.IFrameScrollY = "set";
                        }
                    }
                } catch (e) {}
            });
        }
    }

    public static getSingleton() {
        if (EmbeddingUtil.singleton == null) {
            EmbeddingUtil.singleton = new EmbeddingUtil();
        }
        return EmbeddingUtil.singleton;
    }

    public getContainerHeight = function (element: any, heightPadding: number) {
        if (element && document.contains(element)) {
            return jSith.outerHeight(element) + heightPadding;
        }
    };

    public registerHeightManager(element: any, $scope: any, heightPadding: number, freqMs: number) {
        if (element && element.jquery) {
            element = element[0];
        }
        if (S25Util.isInIframe) {
            jSith.addClass(element, "ngInlineBlock");
            jSith.addClass(element, "ngWidth100");

            heightPadding = heightPadding || 0;
            let lastHeight = this.getContainerHeight(element, heightPadding);
            let detectHeightChangeTimeout: any;

            let detectHeightChange = () => {
                if (element && document.contains(element)) {
                    let currHeight = this.getContainerHeight(element, heightPadding);
                    if (currHeight !== lastHeight) {
                        lastHeight = currHeight;
                        S25Util.postMessage({ type: "height", data: lastHeight });
                    }
                    detectHeightChangeTimeout = setTimeout(detectHeightChange, freqMs);
                }
            };

            S25Util.postMessage({ type: "height", data: lastHeight });
            detectHeightChange();
        }
    }
}
