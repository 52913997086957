import { S25Util } from "../../util/s25-util";

export const S25ScrollUtil = {
    /**
     * Returns the vertical and horizontal scroll limits of the provided elements
     * @param scrollElementX The element that will be scrolled horizontally
     * @param scrollElementY The element that will be scrolled vertically
     * @param windowElement The element which represents the "window" in the minimap
     */
    getScrollLimit(scrollElementX: HTMLElement, scrollElementY: HTMLElement, windowElement: HTMLElement) {
        const windowOffset = S25Util.pageOffset(windowElement);
        const offsetTop = windowOffset.top - S25Util.pageOffset(scrollElementY).top;
        const offsetLeft = windowOffset.left - S25Util.pageOffset(scrollElementX).left;

        let offsetBottom = 0;
        let offsetRight = 0;
        const limit = {
            top: { min: 0, max: scrollElementY.scrollHeight },
            left: { min: 0, max: scrollElementX.scrollWidth },
        };

        // If the element that scrolls contains the window element we need to limit the scroll to the position of the window
        if (!windowElement.contains(scrollElementY)) {
            offsetBottom = scrollElementY.scrollHeight - offsetTop - windowElement.offsetHeight;
            limit.top.min = offsetTop;
            limit.top.max =
                windowElement.offsetHeight + offsetTop - Math.min(scrollElementY.clientHeight, window.innerHeight);
        }
        if (!windowElement.contains(scrollElementX)) {
            offsetRight = scrollElementX.scrollWidth - offsetLeft - windowElement.offsetWidth;
            limit.left.min = offsetLeft;
            limit.left.max =
                windowElement.offsetWidth + offsetLeft - Math.min(scrollElementY.clientWidth, window.innerWidth);
        }

        return { limit, offsetBottom, offsetRight, offsetTop, offsetLeft };
    },
};
