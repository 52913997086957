//@author devin
import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "./s25-util";
import { S25Const } from "./s25-const";

export class MasterDefinitionUtil {
    public static get(serviceUrl: string, rootNodeName: string, itemNodeName: string) {
        return DataAccess.get(serviceUrl).then(function (data) {
            return (
                (data &&
                    data[rootNodeName] &&
                    data[rootNodeName][itemNodeName] &&
                    S25Util.array.forceArray(data[rootNodeName][itemNodeName])) ||
                []
            ).map((item: any) => {
                item.attribute_type_name = S25Const.custAtrbType2Name[item.attribute_type] || item.attribute_type_name;
                return item;
            });
        });
    }

    public static getSpecific(
        serviceUrl: string,
        rootNodeName: string,
        itemNodeName: string,
        idNodeName: string,
        itemId: number,
    ) {
        serviceUrl = S25Util.addParam(serviceUrl, idNodeName, itemId);
        return MasterDefinitionUtil.get(serviceUrl, rootNodeName, itemNodeName).then(function (data) {
            return data && data.length && data[0];
        });
    }

    public static delete(serviceUrl: string, idNodeName: string, itemId: number) {
        serviceUrl = S25Util.addParam(serviceUrl, idNodeName, itemId);
        return DataAccess.delete(serviceUrl);
    }

    public static forceDelete(serviceUrl: string, idNodeName: string, itemId: number) {
        let type: string = "";
        let itemTypeId: number = 0;

        if (idNodeName.indexOf("attr") > -1) {
            type = "custom_attribute";
            if (serviceUrl.indexOf("ev") > -1) {
                itemTypeId = 1;
            } else if (serviceUrl.indexOf("rm") > -1) {
                itemTypeId = 4;
            } else if (serviceUrl.indexOf("res") > -1) {
                itemTypeId = 6;
            } else if (serviceUrl.indexOf("org") > -1) {
                itemTypeId = 2;
            } else {
                //con
                itemTypeId = 3;
            }
        } else if (idNodeName.indexOf("category") > -1) {
            type = "category";
            if (serviceUrl.indexOf("ev") > -1) {
                itemTypeId = 1;
            } else if (serviceUrl.indexOf("rm") > -1) {
                itemTypeId = 4;
            } else if (serviceUrl.indexOf("rs") > -1) {
                itemTypeId = 6;
            } else {
                //org
                itemTypeId = 2;
            }
        } else if (idNodeName.indexOf("requirement") > -1) {
            type = "requirement";
            itemTypeId = 1;
        } else if (idNodeName.indexOf("role") > -1) {
            if (serviceUrl.indexOf("ev") > -1) {
                type = "event_contact_role";
                itemTypeId = 1;
            } else {
                //org
                type = "organization_contact_role";
                itemTypeId = 2;
            }
        } else if (serviceUrl.indexOf("org") > -1 && idNodeName.indexOf("type") > -1) {
            type = "organization_types";
            itemTypeId = 2;
        } else if (serviceUrl.indexOf("ev") > -1 && idNodeName.indexOf("type") > -1) {
            type = "event_types";
            itemTypeId = 1;
        } else if (idNodeName.indexOf("rating") > -1) {
            type = "organization_ratings";
            itemTypeId = 2;
        } else if (idNodeName.indexOf("layout") > -1) {
            type = "location_layouts";
            itemTypeId = 4;
        } else if (idNodeName.indexOf("partition") > -1) {
            type = "location_partitions";
            itemTypeId = 4;
        } else if (idNodeName.indexOf("feature") > -1) {
            type = "location_features";
            itemTypeId = 4;
        }

        return DataAccess.delete(
            "/master/definition.json?itemId=" + itemId + "&itemTypeId=" + itemTypeId + "&type_name=" + type,
        );
    }

    public static setter(
        serviceUrl: string,
        rootNodeName: string,
        itemNodeName: string,
        idNodeName: string,
        itemId: number,
        status: string,
        payload: any,
    ) {
        return MasterDefinitionUtil.getSpecific(serviceUrl, rootNodeName, itemNodeName, idNodeName, itemId).then(
            function (item) {
                if (item) {
                    if (payload) {
                        Object.assign(item, payload);
                        serviceUrl = S25Util.addParam(serviceUrl, idNodeName, itemId);
                        return DataAccess.put(
                            serviceUrl,
                            S25Util.getPayload(rootNodeName, itemNodeName, idNodeName, status, itemId, item),
                        );
                    }
                }
            },
        );
    }

    public static setName(
        serviceUrl: string,
        rootNodeName: string,
        itemNodeName: string,
        idNodeName: string,
        nameNodeName: string,
        itemId: number,
        itemName: string,
    ) {
        let payload: any = {};
        payload[nameNodeName] = itemName;
        return MasterDefinitionUtil.setter(serviceUrl, rootNodeName, itemNodeName, idNodeName, itemId, "mod", payload);
    }

    public static setActive(
        serviceUrl: string,
        rootNodeName: string,
        itemNodeName: string,
        idNodeName: string,
        itemId: number,
        active: boolean,
    ) {
        let activeStr: string = active ? "1" : "0";
        return MasterDefinitionUtil.setter(serviceUrl, rootNodeName, itemNodeName, idNodeName, itemId, "mod", {
            defn_state: activeStr,
        });
    }

    public static setValue(
        serviceUrl: string,
        rootNodeName: string,
        itemNodeName: string,
        idNodeName: string,
        valueNodeName: string,
        itemId: number,
        value: any,
    ) {
        let payload: any = {};
        payload[valueNodeName] = value;
        return MasterDefinitionUtil.setter(serviceUrl, rootNodeName, itemNodeName, idNodeName, itemId, "mod", payload);
    }

    public static setValues(
        serviceUrl: string,
        rootNodeName: string,
        itemNodeName: string,
        idNodeName: string,
        itemId: number,
        paramMap: any,
    ) {
        return MasterDefinitionUtil.setter(serviceUrl, rootNodeName, itemNodeName, idNodeName, itemId, "mod", paramMap);
    }

    public static getNewId(serviceUrl: string, idNodeName: string) {
        return DataAccess.post(serviceUrl).then(function (data) {
            return parseInt(S25Util.propertyGet(data, idNodeName));
        });
    }

    public static createNew(
        serviceUrl: string,
        rootNodeName: string,
        itemNodeName: string,
        idNodeName: string,
        nameNodeName: string,
        paramMap?: any,
    ) {
        return MasterDefinitionUtil.getNewId(serviceUrl, idNodeName).then(function (itemId) {
            let itemName = "New_" + itemId;
            let payload: any = paramMap || {};
            payload[nameNodeName] = itemName;
            return DataAccess.post(
                serviceUrl,
                S25Util.getPayload(rootNodeName, itemNodeName, idNodeName, "new", itemId, payload),
            ).then(function () {
                let ret: any = payload || {};
                ret[idNodeName] = itemId;
                ret[nameNodeName] = itemName;
                ret.defn_state = 1;

                return ret;
            });
        });
    }
}
